import { useUrlHelpers } from '~/composables/useUrlHelpers'
import type { Space } from '~/models/space/Space'
import { useStringHelpers } from '#build/imports'

export function useSeoHelpers() {
  const defaultImageUrl =
    'https://res.cloudinary.com/emfesta-com-br/image/upload/v1671854585/blog/instagram_profile8_tllwke.png'
  const twitterCardType = 'summary_large_image'

  const createIndexPageHead = () => {
    const title = 'Emfesta - Sua diversão começa aqui'
    const description = `Encontre o espaço ou casa de festa ideal para o seu evento aqui na Emfesta`
    const imageUrl = defaultImageUrl
    const url = useRuntimeConfig().public.appBaseUrl

    createDefaultHead(url)
    createSeoMeta(title, description, imageUrl, url)
  }

  const createOwnerLandingPageHead = () => {
    const title = 'Emfesta - Divulgue seu espaço ou casa de festas'
    const description = `Donos de espaços ou casa de festas, divulgue seu espaço ou casa de festas aqui na Emfesta`
    const imageUrl = defaultImageUrl
    const url = `${useRuntimeConfig().public.appBaseUrl}/divulgar-espaco`

    createDefaultHead(url)
    createSeoMeta(title, description, imageUrl, url)
  }

  const createContactPageHead = () => {
    const title = 'Emfesta - Contato'
    const description = `Entre em contato conosco e tire suas dúvidas`
    const imageUrl = defaultImageUrl
    const url = `${useRuntimeConfig().public.appBaseUrl}/contato`

    createDefaultHead(url)
    createSeoMeta(title, description, imageUrl, url)
  }

  const createPrivacyPageHead = () => {
    const title = 'Emfesta - Política de privacidade'
    const description = `Leio nossos termos de uso e política de privacidade`
    const imageUrl = defaultImageUrl
    const url = `${useRuntimeConfig().public.appBaseUrl}/privacidade`

    createDefaultHead(url)
    createSeoMeta(title, description, imageUrl, url)
  }

  const createSearchPageHead = (filters: any) => {
    const address = getAddressFromFilters(filters)
    const title = `Emfesta - Busca de espaços e casas de festas ${address}`
    const description = `Encontre o espaço ou casa de festa ideal para o seu evento. ${address} - Emfesta`
    const imageUrl = defaultImageUrl
    const url = `${
      useRuntimeConfig().public.appBaseUrl
    }/${useStringHelpers().createUrlFromFilters(filters)}`

    createDefaultHead(url)
    createSeoMeta(title, description, imageUrl, url)
  }

  const createSpaceDetailsPageHead = (space: Space) => {
    const title = `Espaço ou Casa de festas ${space.name}. ${space.address.route}, ${space.address.number} - ${space.address.neighborhood}, ${space.address.city} - Emfesta`
    const description = `Espaço ou Casa de festas ${space.name}. ${space.address.route}, ${space.address.number} - ${space.address.neighborhood}, ${space.address.city} - Emfesta`
    const imageUrl = space.coverPhotoUrl
    const url = `${
      useRuntimeConfig().public.appBaseUrl
    }/${useUrlHelpers().getSpaceUrl(space)}`

    createDefaultHead(url)
    createSeoMeta(title, description, imageUrl, url)
  }

  const createArticlePageHead = (article: Article) => {
    const title = `${article.title} - Emfesta`
    const description = `${article.metaDescription} - Emfesta`
    const imageUrl = article.coverPhotoUrl
    const url = `${useRuntimeConfig().public.appBaseUrl}/blog/${article.slug}`

    createDefaultHead(url)
    createSeoMeta(title, description, imageUrl, url)
  }

  const createBlogPageHead = () => {
    const title = 'Emfesta - Blog'
    const description = `Novidades do nosso blog`
    const imageUrl = defaultImageUrl
    const url = `${useRuntimeConfig().public.appBaseUrl}/blog`

    createDefaultHead(url)
    createSeoMeta(title, description, imageUrl, url)
  }

  const createDefaultHead = (url: any) => {
    useHead({
      htmlAttrs: {
        lang: 'pt_BR',
      },
      link: [
        {
          rel: 'icon',
          href: '/favicon.ico',
        },
        {
          rel: 'canonical',
          href: url,
        },
      ],
      meta: [{ name: 'robots', content: 'index, follow' }],
    })
  }

  const createSeoMeta = (
    title: string,
    description: string,
    imageUrl: string,
    url: string
  ) => {
    useSeoMeta({
      title,
      ogTitle: title,
      description,
      ogDescription: description,
      ogImage: imageUrl,
      ogUrl: url,
      twitterTitle: title,
      twitterDescription: description,
      twitterImage: imageUrl,
      twitterCard: twitterCardType,
    })
  }

  const getAddressFromFilters = (filters: any) => {
    let address = ''
    let city = filters.city
    let neighborhood = filters.neighborhood
    if (city && city !== '' && !neighborhood) {
      city = useStringHelpers().capitalize(city)
      address = address + ' em ' + city
    }

    if (city && city !== '' && neighborhood && neighborhood !== '') {
      city = useStringHelpers().capitalize(city)
      neighborhood = useStringHelpers().capitalize(neighborhood)
      address = address + ' em ' + neighborhood + ', ' + city
    }
    return address
  }

  return {
    createIndexPageHead,
    createContactPageHead,
    createPrivacyPageHead,
    createOwnerLandingPageHead,
    createSearchPageHead,
    createSpaceDetailsPageHead,
    createBlogPageHead,
    createArticlePageHead,
  }
}
