import { SwiperOptions } from 'swiper/types'
import { register } from 'swiper/element/bundle'

register() // swiper initialization

export function useSwiperGallery() {
  const swiperElement: any = () => {
    const swiperEl: any = document.querySelector('swiper-container')
    const swiperParams: SwiperOptions = {
      speed: 500,
      spaceBetween: 4,
      slidesPerView: 1,
      initialSlide: 0,
      centeredSlides: true,
      centeredSlidesBounds: true,
      pagination: {
        type: 'fraction',
      },
      navigation: true,
      rewind: true,
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      breakpoints: {
        800: {
          slidesPerView: 2,
          spaceBetween: 3,
        },
        1600: {
          slidesPerView: 3,
          spaceBetween: 3,
        },
      },
      injectStyles: [
        `
          .swiper-button-next,
          .swiper-button-prev {
            padding: 12px 16px;
            border-radius: 32px;
            color: white;
            font-weight: 900;
            background-color: rgba(0,0,0, 0.6);
            border: 1px solid rgba(255,255,255, 0.3);
            --swiper-navigation-size: 20px;
            --swiper-navigation-sides-offset: 16px;
          }
          .swiper-pagination-fraction {
            width: 80px;
            background-color: rgba(0,0,0, 0.6);
            color: white;
            border-radius: 0 8px 8px 0;
          }
      `,
      ],
    }
    Object.assign(swiperEl, swiperParams)
    swiperEl.initialize()
  }

  return {
    swiperElement,
  }
}
