import { SwiperOptions } from 'swiper/types'
import { register } from 'swiper/element/bundle'

register() // swiper initialization

export function useSwiperCarousel() {
  const swiperElement: any = () => {
    const swiperEl: any = document.querySelector('swiper-container')
    const swiperParams: SwiperOptions = {
      speed: 900,
      slidesPerView: 1,
      initialSlide: 0,
      autoplay: {
        delay: 4000,
      },
      effect: 'cards',
      rewind: true,
      keyboard: {
        enabled: false,
      },
      injectStyles: [
        `
      `,
      ],
    }
    Object.assign(swiperEl, swiperParams)
    swiperEl.initialize()
  }

  return {
    swiperElement,
  }
}
